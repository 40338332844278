import React from "react"
import Layout from "../components/Layout"
import { Box, Heading, Text, Divider } from "@chakra-ui/core"
import { FaWhatsapp, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa"

import CustomIcon from "../components/CustomIcon"

const ContactPage = () => (
  <Layout>
    <Box p={5} h={400}>
      <Heading textAlign="center"> Contact Us </Heading>
      <Divider />
      <Text mt={10} fontSize="lg">
        Contact us on any of our social media handles
      </Text>
      <Box m={10}>
        <CustomIcon mt={2} iconComponent={FaInstagram} text="/schoolofbirds" link="https://instagram.com/schoolofbirds" />
        <CustomIcon mt={2} iconComponent={FaFacebook} text="/schoolofbirds" link="https://facebook.com/schoolofbirds" />
        <CustomIcon mt={2} iconComponent={FaYoutube} text="/schoolofbirds" link="https://youtube.com/schoolofbirds" />
        <CustomIcon mt={2} iconName="email" text="schoolofbirds@gmail.com" link="mailto:schoolofbirds@gmail.com" />
        <CustomIcon mt={2} iconComponent={FaWhatsapp} text="+91-8335094161" link="https://wa.me/8335094161" />
        <CustomIcon mt={2} iconName="phone" text="+91-7980383950" />
      </Box>
    </Box>
  </Layout>
)
export default ContactPage
